import { types } from "react-bricks/frontend"

const pageTypes: types.IPageType[] = [
    {
        name: "page",
        pluralName: "pages",
        defaultLocked: false,
        defaultStatus: types.PageStatus.Draft,
        defaultLanguage: "de",
        getDefaultContent: () => [],
    },
    {
        name: "landing page",
        pluralName: "landing pages",
        defaultLocked: false,
        defaultStatus: types.PageStatus.Draft,
        defaultLanguage: "de",
        getDefaultContent: () => [],
    },
    {
        name: "archive page",
        pluralName: "archive pages",
        defaultLocked: false,
        defaultStatus: types.PageStatus.Draft,
        defaultLanguage: "de",
        getDefaultContent: () => [],
    },
]

export default pageTypes
