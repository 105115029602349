import { getCookies } from "./cookie"

const addSearchParams = href => {
    let newHref = href

    if (typeof window !== "undefined") {
        const cookies = getCookies({
            utm_source: "__utm_source__",
            utm_medium: "__utm_medium__",
            utm_campaign: "__utm_campaign__",
            gclid: "__gclid__",
        })

        let search = window.location.search

        if (Object.values(cookies).filter(v => !!v).length > 0) {
            search = "?"
            Object.keys(cookies).forEach(param => {
                if (cookies[param]) search += `${param}=${cookies[param]}&`
            })
        }

        if (search.charAt(search.length - 1) === "&") {
            search = search.slice(0, -1)
        }

        if (search) {
            const pathnameWithSearch = href.split("#")[0]
            search = search.replace("?", "")
            let hash = href.split("#")[1]
            hash = hash ? "#" + hash : ""
            if (pathnameWithSearch.includes("?")) {
                newHref = pathnameWithSearch + "&" + search + hash
            } else {
                newHref = pathnameWithSearch + "?" + search + hash
            }
        }
    }

    return newHref
}

export { addSearchParams }
