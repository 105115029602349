import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { types } from "react-bricks/frontend"

const Link: types.RenderLocalLink = ({ href, children, className, target, activeClassName }) => {
    return (
        <GatsbyLink to={href} className={className} activeClassName={activeClassName} target={target}>
            {children}
        </GatsbyLink>
    )
}

export default Link
