import { austria, germany, switzerland } from "../styles/flags"

const workingHoursDefault = [null, [8, 19], [8, 19], [8, 19], [8, 19], [8, 19], [9, 14]]
const workingHoursPartner = [null, [8, 18], [8, 18], [8, 18], [8, 18], [8, 16], null]

const allRegions = [
    {
        id: 0,
        countryCode: "DE",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Deutschland",
        languages: ["de", "en"],
        languagePaths: [process.env.IGNORE_REGION_IN_PATH ? "/de?region=DE" : "/de-de", "?region=DE"],
        flag: germany,
    },
    {
        id: 1,
        countryCode: "AT",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Österreich",
        languages: ["de", "en"],
        languagePaths: [process.env.IGNORE_REGION_IN_PATH ? "/de?region=AT" : "/de-at", "?region=AT"],
        flag: austria,
    },
    {
        id: 2,
        countryCode: "CH",
        workingHours: [null, [8, 17], [8, 17], [8, 17], [8, 17], [8, 15], null],
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_CH,
        name: "Schweiz",
        languages: ["de", "fr", "it", "en"],
        languagePaths: [process.env.IGNORE_REGION_IN_PATH ? "?region=CH" : "/de-ch", process.env.IGNORE_REGION_IN_PATH ? "/en?region=CH" : "/fr-ch", process.env.IGNORE_REGION_IN_PATH ? "/en?region=CH": "/it-ch", process.env.IGNORE_REGION_IN_PATH ? "/en?region=CH" : "/en-ch"],
        flag: switzerland,
    },
    {
        id: 3,
        countryCode: "BE",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "België",
        languages: ["nl", "fr", "en"],
        languagePaths: [process.env.IGNORE_REGION_IN_PATH ? "?region=BE" : "/nl-be", process.env.IGNORE_REGION_IN_PATH ? "?region=BE" : "/fr-be", "?region=BE"],
    },
    {
        id: 4,
        countryCode: "CZ",
        redirect: process.env.REDIRECT_CZ,
        name: "Česko",
        languages: ["cs"],
        languagePaths: ["/cs-cz"],
    },
    {
        id: 5,
        countryCode: "CY",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Cyprus",
        languages: ["en"],
        languagePaths: ["?region=CY"],
    },
    {
        id: 6,
        countryCode: "DK",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Danmark",
        languages: ["da", "en"],
        languagePaths: [process.env.IGNORE_REGION_IN_PATH ? "?region=DK" : "/da-dk", "?region=DK"],
    },
    {
        id: 7,
        countryCode: "EE",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Eesti",
        languages: ["en"],
        languagePaths: ["?region=EE"],
    },
    {
        id: 8,
        countryCode: "ES",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "España",
        languages: ["es", "en"],
        languagePaths: [process.env.IGNORE_REGION_IN_PATH ? "?region=ES" : "/es-es", "?region=ES"],
    },
    {
        id: 9,
        countryCode: "FR",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "France",
        languages: ["fr", "en"],
        languagePaths: [process.env.IGNORE_REGION_IN_PATH ? "?region=FR" : "/fr-fr", "?region=FR"],
    },
    {
        id: 10,
        countryCode: "GB",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Great Britain",
        languages: ["en"],
        languagePaths: ["?region=GB"],
    },
    {
        id: 11,
        countryCode: "HR",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Hrvatska",
        languages: ["en"],
        languagePaths: ["?region=HR"],
    },
    {
        id: 12,
        countryCode: "IE",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Ireland",
        languages: ["en"],
        languagePaths: ["?region=IE"],
    },
    {
        id: 13,
        countryCode: "IS",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Ísland",
        languages: ["en"],
        languagePaths: ["?region=IS"],
    },
    {
        id: 14,
        countryCode: "IT",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Italia",
        languages: ["it", "en"],
        languagePaths: [process.env.IGNORE_REGION_IN_PATH ? "?region=IT" : "/it-it", "?region=IT"],
    },
    {
        id: 15,
        countryCode: "LV",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Latvija",
        languages: ["en"],
        languagePaths: ["?region=LV"],
    },
    {
        id: 16,
        countryCode: "LI",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Liechtenstein",
        languages: ["de", "en"],
        languagePaths: [process.env.IGNORE_REGION_IN_PATH ? "/de?region=LI" : "/de-li", "?region=LI"],
    },
    {
        id: 17,
        countryCode: "LT",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Lietuva",
        languages: ["en"],
        languagePaths: ["?region=LT"],
    },
    {
        id: 18,
        countryCode: "LU",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Luxembourg",
        languages: ["fr", "de", "en"],
        languagePaths: [process.env.IGNORE_REGION_IN_PATH ? "?region=LU" : "/fr-lu", process.env.IGNORE_REGION_IN_PATH ? "/de?region=LU" : "/de-lu", "?region=LU"],
    },
    {
        id: 19,
        countryCode: "HU",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Magyarország",
        languages: ["en"],
        languagePaths: ["?region=HU"],
    },
    {
        id: 20,
        countryCode: "MT",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Malta",
        languages: ["en"],
        languagePaths: ["?region=MT"],
    },
    {
        id: 21,
        countryCode: "NL",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Nederland",
        languages: ["nl", "en"],
        languagePaths: [process.env.IGNORE_REGION_IN_PATH ? "?region=NL" : "/nl-nl", "?region=NL"],
    },
    {
        id: 22,
        countryCode: "NO",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Norge",
        languages: ["no", "en"],
        languagePaths: [process.env.IGNORE_REGION_IN_PATH ? "?region=NO" : "/no-no", "?region=NO"],
    },
    {
        id: 23,
        countryCode: "PL",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Polska",
        languages: ["en"],
        languagePaths: ["?region=PL"],
    },
    {
        id: 24,
        countryCode: "PT",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Portugal",
        languages: ["en"],
        languagePaths: ["?region=PT"],
    },
    {
        id: 25,
        countryCode: "RO",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "România",
        languages: ["en"],
        languagePaths: ["?region=RO"],
    },
    {
        id: 26,
        countryCode: "SI",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Slovenija",
        languages: ["en"],
        languagePaths: ["?region=SI"],
    },
    {
        id: 27,
        countryCode: "SK",
        redirect: process.env.REDIRECT_CZ,
        name: "Slovensko",
        languages: ["cs"],
        languagePaths: ["/cs-cz"],
    },
    {
        id: 28,
        countryCode: "FI",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Suomi",
        languages: ["en"],
        languagePaths: ["?region=FI"],
    },
    {
        id: 29,
        countryCode: "SE",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Sverige",
        languages: ["sv", "en"],
        languagePaths: [process.env.IGNORE_REGION_IN_PATH ? "?region=SE" : "/sv-se", "?region=SE"],
    },
    {
        id: 30,
        countryCode: "TR",
        redirect: process.env.REDIRECT_TR,
        name: "Türkiye",
        languages: ["tr"],
        languagePaths: ["/tr-tr"],
    },
    {
        id: 31,
        countryCode: "GR",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Ελλάδα",
        languages: ["en"],
        languagePaths: ["?region=GR"],
    },
    {
        id: 32,
        countryCode: "BG",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "България",
        languages: ["en"],
        languagePaths: ["?region=BG"],
    },
    // this only exists for the tag "EN" in react bricks
    {
        id: 33,
        countryCode: "EN",
        workingHours: workingHoursDefault,
        workingHoursPartner: workingHoursPartner,
        redirect: process.env.REDIRECT_COM,
        name: "Great Britain",
        languages: ["en"],
        languagePaths: ["?region=EN"],
    },
]

const regions = allRegions.filter(r => process.env.REGIONS.includes(r.countryCode))

export { regions, allRegions }
