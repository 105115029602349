import { Background, BackgroundColor, Container, Spacer, SpacerVariant } from "../components/layout"
import React, { Children, useEffect, useRef, useState } from "react"
import { Repeater, Text, types, useAdminContext, usePageValues } from "react-bricks/frontend"

import { PDPProps } from "./PDP"
import { Product } from "@lavita-io/international-sdk"
import classNames from "classnames"
import client from "../../shared/utils/client"
import { twMerge } from "tailwind-merge"
import { useClientState } from "../../shared/context/client"
import { useLocalesState } from "../../shared/context/locales"
import { usePageContext } from "../../context/page"
import { useQuery } from "@tanstack/react-query"
import { useScrollSize } from "../../hooks/use-scroll-size"
import { useWindowSize } from "../../hooks/use-window-size-admin"

export interface TabbedPDPProps {
    repeater?: {
        id: string
        props: PDPProps
        type: "PDP"
    }[]
    spacerTop: boolean
    spacerBottom: boolean
    id?: string
}

const TabbedPDP: types.Brick<TabbedPDPProps> = ({ repeater, spacerTop, spacerBottom, id }) => {
    const { region } = useLocalesState()
    const { products: productsBuildTime, page: pageContext } = usePageContext()
    const { consumerToken } = useClientState()

    const [pageValues] = usePageValues()

    const page = pageContext || pageValues

    const { data } = useQuery<Product[]>(
        ["products", ...(repeater?.map(r => r.props.sku).filter(r => r) || []), region, page?.language],
        () =>
            client.getProducts(
                { sku: repeater?.map(r => r.props.sku).filter(r => r), country: region },
                page?.language
            ),
        {
            enabled: !!(repeater?.length && page && consumerToken),
        }
    )

    const products = data || productsBuildTime

    const [activeTab, setActiveTab] = useState(0)

    const ref = useRef<HTMLDivElement>(null)

    const changeTab = (id: string) => {
        if (!repeater) return
        setActiveTab(repeater.findIndex(r => r.id === id))
        document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
    }

    const { width } = useWindowSize({ initializeWithValue: false, debounceDelay: 200 })

    const { width: tabWidth } = useScrollSize({ ref, initializeWithValue: false, debounceDelay: 200 })

    const { isAdmin } = useAdminContext()

    useEffect(() => {
        if (!products || !repeater) return

        setActiveTab(repeater.findIndex(r => !r.props.sku || products.map(p => p.sku).includes(r.props.sku)) || 0)
    }, [products, repeater])

    return (
        <Background color={BackgroundColor.White} className="overflow-hidden">
            <Container>
                <Spacer variant={!spacerTop ? SpacerVariant.None : SpacerVariant.Small} />
                <div className="flex flex-col" id={id}>
                    {repeater && repeater.length > 1 && (
                        <div
                            ref={ref}
                            className={twMerge(
                                classNames(
                                    "-mx-6.5 mb-7 flex flex-row gap-10 overflow-auto px-6.5 sm:mx-0 sm:mb-10 sm:justify-center md:gap-10 lg:mb-16 lg:gap-16",
                                    {
                                        "justify-center": !!width && !!tabWidth && width >= tabWidth,
                                    }
                                )
                            )}
                        >
                            {repeater.map((r, i) => {
                                if (r.props.sku && !products?.find(p => p.sku === r.props.sku) && !isAdmin) return null
                                return (
                                    <div
                                        key={r.id}
                                        id={r.id}
                                        className={twMerge(
                                            "relative mb-3.5 flex min-w-fit items-center justify-center text-center text-md font-semibold text-blue opacity-30 sm:text-lg lg:text-xl",
                                            activeTab === i &&
                                                "opacity-100 after:absolute after:-bottom-2.5 after:h-1 after:w-[calc(100%_+_8px)] after:rounded-[2px] after:bg-blue"
                                        )}
                                    >
                                        <Text
                                            propName={`title-${r.id}`}
                                            renderBlock={({ children }) => (
                                                <button className="h-full w-full" onClick={() => changeTab(r.id)}>
                                                    {children}
                                                </button>
                                            )}
                                            renderPlaceholder={({ children }) => (
                                                <button className="h-full w-full" onClick={() => changeTab(r.id)}>
                                                    {children}
                                                </button>
                                            )}
                                            placeholder="Lorem ipsum"
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    <Repeater
                        propName="repeater"
                        itemProps={
                            {
                                activeTab,
                                inline: true,
                                products,
                            } as PDPProps
                        }
                    />
                </div>
                <Spacer variant={!spacerBottom ? SpacerVariant.None : SpacerVariant.Small} />
            </Container>
        </Background>
    )
}

TabbedPDP.schema = {
    name: "TabbedPDP",
    label: "Tabbed PDP",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "PDP",
                    label: "Product",
                    min: 2,
                },
            ],
        },
    ],
    sideEditProps: [
        {
            name: "sku",
            label: "aktive SKU",
            type: types.SideEditPropType.Text,
        },
        {
            name: "id",
            label: "ID",
            type: types.SideEditPropType.Text,
        },
        {
            groupName: "Spacer",
            props: [
                {
                    name: "spacerTop",
                    label: "Spacer Top",
                    type: types.SideEditPropType.Boolean,
                },
                {
                    name: "spacerBottom",
                    label: "Spacer Bottom",
                    type: types.SideEditPropType.Boolean,
                },
            ],
        },
    ],
    getDefaultProps: (): TabbedPDPProps => ({
        spacerTop: true,
        spacerBottom: true,
    }),
}

export default TabbedPDP
