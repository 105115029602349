import { useCallback, useEffect, useState } from "react"

const useLocalstorage = (key, initialValue, options) => {
    const storage = options === "sessionStorage" ? "sessionStorage" : "localStorage"

    const readValue = () => {
        // Prevent build error "window is undefined" but keep it working
        if (typeof window === "undefined") {
            return initialValue
        }

        const item = window[storage].getItem(key)
        try {
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            console.warn(`Error reading ${storage} key “${key}”:`, error)
            return item || initialValue
        }
    }

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(readValue)

    const setValue = useCallback(
        value => {
            if (storedValue === value) return

            // Prevent build error "window is undefined" but keeps working
            if (typeof window === "undefined") {
                console.warn(`Tried setting ${storage} key “${key}” even though environment is not a client`)
            }

            try {
                // Allow value to be a function so we have the same API as useState
                const newValue = value instanceof Function ? value(storedValue) : value

                if (newValue === undefined || newValue === null) {
                    window[storage].removeItem(key)
                } else {
                    window[storage].setItem(key, JSON.stringify(newValue))
                }

                // Save state
                setStoredValue(newValue)

                // We dispatch a custom event so every useStorage hook are notified
                window.dispatchEvent(
                    new CustomEvent("onStorageChange", {
                        detail: { key, newValue },
                    })
                )
            } catch (error) {
                console.warn(`Error setting ${storage} key “${key}”:`, error)
            }
        },
        [key, storage, storedValue]
    )

    useEffect(() => {
        const handleStorageChange = event => {
            if (!!event && event.detail && event.type === "onStorageChange") {
                if (event.detail.key === key) {
                    setStoredValue(event.detail.newValue)
                }
            } else {
                if (event.key === key) {
                    setStoredValue(event.newValue === null ? null : JSON.parse(event.newValue))
                }
            }
        }

        // The storage event only works in the context of other documents (eg. other browser tabs)
        window.addEventListener("storage", handleStorageChange)

        // The custom storage event allows us to update our component
        // when a change occurs in storage outside of our component
        window.addEventListener("onStorageChange", handleStorageChange)

        return () => {
            window.removeEventListener("storage", handleStorageChange)
            window.removeEventListener("onStorageChange", handleStorageChange)
        }
    }, [key])

    return [storedValue, setValue]
}

export { useLocalstorage }
