import { headerBackgroundOptions, headerStyleOptions } from "../types"

import Link from "./Link"
import bricks from "./bricks"
import { navigate } from "gatsby"
import pageTypes from "./pageTypes"
import { types } from "react-bricks/frontend"

const config: types.ReactBricksConfig = {
    appId: process.env.REACT_BRICKS_APP_ID || "",
    apiKey: process.env.REACT_BRICKS_API_KEY || "",
    pageTypes,
    bricks,
    logo: process.env.IMG_CDN_URL_ASSETS + "/logo/LaVita_Logo_2020.png",
    loginUI: {
        logo: process.env.IMG_CDN_URL_ASSETS + "/logo/LaVita_Logo_2020.png",
        welcomeText: "",
    },
    previewPath: "/de-de/preview",
    renderLocalLink: Link,
    navigate,
    loginPath: "/admin",
    getAdminMenu: ({ isAdmin }) => [
        {
            label: "Editor",
            path: "/admin/editor",
        },
        {
            label: "Media",
            path: "/admin/media",
        },
        {
            label: "Playground",
            path: "/admin/playground",
        },
        {
            label: isAdmin ? "Settings" : "Deploy",
            path: "/admin/app-settings",
        },
    ],
    useCssInJs: false,
    appRootElement: "#___gatsby",
    enablePreviewImage: true,
    clickToEditSide: types.ClickToEditSide.BottomRight,
    customFields: [
        {
            groupName: "Header",
            props: [
                {
                    name: "headerBackground",
                    label: "Background",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: headerBackgroundOptions,
                        display: types.OptionsDisplay.Select,
                    },
                },
                {
                    name: "headerStyle",
                    label: "Style",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: headerStyleOptions,
                        display: types.OptionsDisplay.Select,
                    },
                },
            ],
        },
        {
            groupName: "Custom meta tags",
            props: [
                {
                    name: "robots",
                    label: "Robots",
                    type: types.SideEditPropType.Select,
                    selectOptions: {
                        options: [
                            { value: "all", label: "all" },
                            { value: "noindex", label: "noindex" },
                            { value: "nofollow", label: "nofollow" },
                            { value: "none", label: "none" },
                        ],
                        display: types.OptionsDisplay.Select,
                    },
                },
                {
                    name: "alternates",
                    label: "Alternates",
                    type: types.SideEditPropType.Textarea,
                },
            ],
        },
    ],
    responsiveBreakpoints: [
        { type: types.DeviceType.Phone, width: "320px", label: "Mobile" },
        { type: types.DeviceType.Tablet, width: "768px", label: "Tablet Portrait" },
        { type: types.DeviceType.Tablet, width: "1024px", label: "Tablet Landscape" },
        { type: types.DeviceType.Tablet, width: "1366px", label: "iPad Pro" },
        { type: types.DeviceType.Desktop, width: "100%", label: "Desktop" },
    ],
    enableAutoSave: false,
    disableSaveIfInvalidProps: false,
    enablePreview: true,
    experimentalSidebarRepeaterItems: true,
}

export default config
